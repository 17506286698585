import { createContext } from "react";

const UserContext = createContext(null);

export const userData = {
  zipCode: 0,
  monthlyInternetBill: 100,
  tvBundle: "TV",
  numberOfDevicesConnect: 3,
  currentInternetProvider: "",
  monthlyInternetUsage: "Up To 300 MBPS",
  userName: "",
  userEmail: "",
  userPhone: "",
};

export default UserContext;
