import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import "./App.css";
import Footer from "./components/common/Footer";
import LoadingPage from "./components/common/LoadingPage";
import UserContext, { userData } from "./components/Store/Store";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// import ApiText from "./components/dummyfolder/ApiText";

const Home = lazy(() => import("./components/Home/Home"));
const ContactUs = lazy(() => import("./components/otherpages/ContactUs"));
const ErrorPage = lazy(() => import("./components/common/ErrorPage"));
const TermsAndconditions = lazy(() =>
  import("./components/otherpages/TermsAndconditions")
);
const PrivacyPolicy = lazy(() =>
  import("./components/otherpages/PrivacyPolicy")
);
const MonthelyEnergyBill = lazy(() =>
  import("./components/proccess/MonthelyEnergyBill")
);
const BundleTvService = lazy(() =>
  import("./components/proccess/BundleTvService")
);
const HowManyDevice = lazy(() => import("./components/proccess/HowManyDevice"));
const InternetProvider = lazy(() =>
  import("./components/proccess/InternetProvider")
);
const InternetUseage = lazy(() =>
  import("./components/proccess/InternetUseage")
);
const NameEmailPage = lazy(() => import("./components/proccess/NameEmailPage"));
const UserPhoneNumber = lazy(() =>
  import("./components/proccess/UserPhoneNumber")
);
const SavingPage = lazy(() => import("./components/proccess/SavingPage"));
const DoNotSellInfo = lazy(() => import("./components/otherpages/DoNotSell"));

function App() {
  return (
    // basename="/test/otp/"
    <Router >
      <UserContext.Provider value={userData}>
        <HelmetProvider>
          <ToastContainer
            position="top-center"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <Routes>
            <Route
              path="/"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <Home />
                </Suspense>
              }
            />
            <Route
              path="/contactus"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <ContactUs />
                </Suspense>
              }
            />
            <Route
              path="/terms-and-condition"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <TermsAndconditions />
                </Suspense>
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <PrivacyPolicy />
                </Suspense>
              }
            />
            <Route
              path="/do-not-sell-my-info"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <DoNotSellInfo />
                </Suspense>
              }
            />
            <Route
              path="/calculatebill"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <MonthelyEnergyBill />
                </Suspense>
              }
            />
            <Route
              path="/userbundle"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <BundleTvService />
                </Suspense>
              }
            />
            <Route
              path="/divceconnection"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <HowManyDevice />
                </Suspense>
              }
            />
            <Route
              path="/internetprovider"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <InternetProvider />
                </Suspense>
              }
            />
            <Route
              path="/internetuseage"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <InternetUseage />
                </Suspense>
              }
            />
            <Route
              path="/userdetails"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <NameEmailPage />
                </Suspense>
              }
            />
            <Route
              path="/usercontactpage"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <UserPhoneNumber />
                </Suspense>
              }
            />
            <Route
              path="/savingpage"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <SavingPage />
                </Suspense>
              }
            />
            {/* <Route path="/testapi" element={<ApiText/>} /> */}
            <Route
              path="/*"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <ErrorPage />
                </Suspense>
              }
            />
          </Routes>
          <Footer />
        </HelmetProvider>
      </UserContext.Provider>
    </Router>
  );
}

export default App;
