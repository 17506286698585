import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <section className="bg-black text-white">
        <div className="container px-5 md:px-10 max-w-7xl py-5 2xl:pt-12 mx-auto">
          <div className="flex font-bold justify-between md:justify-center flex-wrap">
            <Link to={"/terms-and-condition"}>
              <span className=" inline-block mx-2 lg:mx-4 my-1 uppercase hover:text-green-700 cursor-pointer duration-300">
                Terms & Disclosures
              </span>
            </Link>
            <span className="hidden md:inline-block mx-2 lg:mx-4 my-1 uppercase  ">
              |
            </span>
            <Link to={"/do-not-sell-my-info"}>
              <span className=" inline-block mx-2 lg:mx-4 my-1 uppercase hover:text-green-700 cursor-pointer duration-300">
                Do not sell my info
              </span>
            </Link>
            <span className="hidden md:inline-block mx-2 lg:mx-4 my-1 uppercase ">
              |
            </span>
            <Link to={"/privacy-policy"}>
              <span className=" inline-block mx-2 lg:mx-4 my-1 uppercase hover:text-green-700 cursor-pointer duration-300">
                Privacy policy
              </span>
            </Link>
            <span className="hidden md:inline-block mx-2 lg:mx-4 my-1 uppercase  ">
              |
            </span>
            <Link to={"/contactus"}>
              <span className=" inline-block mx-2 lg:mx-4 my-1 uppercase hover:text-green-700 cursor-pointer duration-300">
                Contact us
              </span>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
