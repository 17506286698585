import React from "react";
import loadingImage from "../../images/Spinner.gif";

const LoadingPage = () => {
  return (
    <div className="flex items-center justify-center fixed left-0 top-0 z-[1000] h-screen min-h-screen w-full ">
      <div className="">
        <img src={loadingImage} alt="loadingspinner" />
      </div>
    </div>
  );
};

export default LoadingPage;
